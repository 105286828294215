import React, { memo } from 'react';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import { SR_TRY_POMANDA_PLUS } from '../../common/subscriptionConfigConst';
import { EP_SCORECARD_HOMEPAGE, ET_SCORECARD_FAQ_LINK_CLICK } from '../../common/trackingConstants';
import { sendActivityTracking } from '../../redux/actions/commonAction';
import './TryPomandaPlus.css';
import { withRouter } from 'react-router'
import { isBrowser, PROSPER_USER_TYPE } from '../../common/config';
import DynamicSvg from '../../DynamicSvg';


const TryPomandaPlus = memo(function TryPomandaPlus(props) {
    const handleTryNowClick = () => {
        const { userInfo = {},  whiteLabelConfig = {}} = props;
        const iswhiteLabelConfig = whiteLabelConfig?.header ? true : false
        let path = ""
        if (userInfo?.userType === PROSPER_USER_TYPE || iswhiteLabelConfig) {
            path = "/powersearch/company";
        } else {
            const pathname = isBrowser && window.location.pathname
            const href = isBrowser && window.location.href
            path = `/product?serviceReq=${SR_TRY_POMANDA_PLUS}&url=${pathname}&href=${href}`
        }
        props.history.push(path);
    }

    const openScorecardFaq = () => {
        window.open("/assets/Download/Pomanda_Health_Check_FAQs_2022.pdf", '_blank');
        props.dispatchSendActivityTracking({
            eventPage: EP_SCORECARD_HOMEPAGE,
            eventType: ET_SCORECARD_FAQ_LINK_CLICK,
        });
    }

    const getAltAttribute = (imagePath) => {
        if(!imagePath) {
            return "";
        }
        const imageName = imagePath.split("/");
        const altTagAttribute = imageName[imageName.length - 1].split(".")[0];
        return `${altTagAttribute}_image`;
    }
    const {
        imageUrl = {
            jpgSvg: "/assets/images/mobile_try_pomanda.png",
            webp: "/assets/webpImages/mobile_try_pomanda.webp"
        },
        heading = "<span class='try-text'>Try</span> Pomanda Plus",
        paragraphs = ["For access to unlimited company & director profiles, financial data, valuations, free credit reports and much more!"],
        footer = false,
        multiHeading = { deskHeading: "", mobileHeading: "" },
        tryPomandaPlusBtn = false,
        viewSampleBtn = {
            show: false,
        },
        imageIcon = {
            show: false,
            heading: "",
            cornerImg: { pngImgIcon:"", webpImgIcon:"" },
            middleImg: { pngImgIcon:"", webpImgIcon:"" },
            footer: ""
        },
        btnName="Try Now",
        mobileViewMedia="",
        handleContactUs=handleTryNowClick,
        tryPomandaBtnIconClass = "",
        textNote = ""
    } = props;
    return (
        <div className="try-pomanda-plus-section-wrapperr">
            <div id="try_pomanda_plus_section">
                <div className="left-section">
                    {textNote ? <p className='try-pomanda-text-note'>{textNote}</p> : null}
                    {!viewSampleBtn?.show && <p className="heading-text" dangerouslySetInnerHTML={{ __html: heading }}>
                    </p>}
                    <MediaQuery minWidth={991}>
                        {viewSampleBtn?.show && <p className="heading-text" dangerouslySetInnerHTML={{ __html: multiHeading.deskHeading }}>
                        </p>}
                    </MediaQuery>
                    <MediaQuery maxWidth={991}>
                        {viewSampleBtn?.show && <p className="heading-text" dangerouslySetInnerHTML={{ __html: multiHeading.mobileHeading }}>
                        </p>}
                    </MediaQuery>
                    {
                        paragraphs.length > 0
                        && paragraphs.map((paragraphText, index) =>
                            <p className="paragraph-text"
                                key={`try_pomanda_plus_para_${index} `}
                                dangerouslySetInnerHTML={{ __html: paragraphText }}></p>
                        )

                    }
                    {
                        footer &&
                        <p className="paragraph-text">For a full list of Health Check FAQs <span className="help-for-free" pomandae2e="click here" onClick={openScorecardFaq}>click here</span>
                        </p>
                    }
                    {
                        tryPomandaPlusBtn && <button className="yellow-bg-btn" pomandae2e={btnName} onClick={handleContactUs}>{btnName} {tryPomandaBtnIconClass ? <i className={`fa fa-${tryPomandaBtnIconClass} ml-1`}></i> : null}</button>
                    }
                    <MediaQuery minWidth={991}>
                        {
                            viewSampleBtn.show && <button className="yellow-bg-btn" pomandae2e="view sample" onClick={viewSampleBtn.onClick}>view sample</button>
                        }
                    </MediaQuery>
                    {/* <p className="footer-text">Or Register <span className="help-for-free">here for free</span> reduced access to the Pomanda Platform</p> */}
                </div>
                {mobileViewMedia && <MediaQuery maxWidth={575.75}>
                    {!imageIcon.show && <div className="image-parent">
                        <picture>
                            <source data-srcset={imageUrl.webp} type="image/webp" />
                            <img data-src={imageUrl.jpgSvg} className="img-class lazyload"
                                alt={getAltAttribute(imageUrl.jpgSvg)}
                                width={imageUrl.width ? imageUrl.width : "228px"} 
                                height={imageUrl.height ? imageUrl.height : "183px"} />
                        </picture>
                    </div>}
                    </MediaQuery>}
                <MediaQuery minWidth={575.98}>
                    {!imageIcon.show && <div className="image-parent">
                        <picture>
                            <source data-srcset={imageUrl.webp} type="image/webp" />
                            <img data-src={imageUrl.jpgSvg} className="img-class lazyload" 
                            alt={getAltAttribute(imageUrl.jpgSvg)}
                            width={imageUrl?.width || "100%"} height={imageUrl?.height || "100%"}></img>
                        </picture>
                    </div>}
                </MediaQuery>
                {viewSampleBtn.show && <MediaQuery maxWidth={575}> <div className="image-parent">
                    <picture>
                        <source srcSet={imageUrl.webp} type="image/webp" className="img-class" />
                        <source srcSet={imageUrl.jpgSvg} type="image/png" className="img-class" />
                        <img src={imageUrl.jpgSvg} className="img-class" alt={getAltAttribute(imageUrl.jpgSvg)}  width="70%" height="100%"></img>
                    </picture>
                </div>
                </MediaQuery>}
                {imageIcon.show && <div className="image-parent">
                    <div className="rectangular-card-icon">
                        {imageIcon.cornerImg?.dynamicSvg? 
                        <DynamicSvg alt={imageIcon.cornerImg.alt} src={`/assets/svgs/${imageIcon.cornerImg.svgImgIcon}`} 
                        className="corner-image" width="100%" height="100%" /> :
                        <picture >
                            <source srcSet={`/assets/svgs/${imageIcon.cornerImg.webpImgIcon}`} />
                            <img src={`/assets/svgs/${imageIcon.cornerImg.pngImgIcon}`} 
                            className="corner-image" width="100%" height="100%" alt={imageIcon?.cornerImg?.alt} />
                        </picture>}
                        <picture >
                            <source srcSet={`/assets/svgs/${imageIcon.middleImg.webpImgIcon}`} />
                            <img src={`/assets/svgs/${imageIcon.middleImg.pngImgIcon}`} className="middle-image lazyload"
                            width="20%" height="10%" alt={imageIcon?.middleImg?.alt} />
                            </picture>
                        <div className="rectangular-card-icon-text">
                            {imageIcon.heading}
                        </div>
                        <div className="rectangular-card-icon-footer-section">
                            <div className="rectangular-card-icon-footer">
                                {imageIcon.footer}
                            </div>
                        </div>
                        {imageIcon.cornerImg?.dynamicSvg? 
                        <DynamicSvg alt={imageIcon.cornerImg.alt} src={`/assets/svgs/${imageIcon.cornerImg.svgImgIcon}`} width="100%" height="100%"
                        className="right-corner-image" /> :<picture >
                            <source srcSet={`/assets/svgs/${imageIcon.cornerImg.webpImgIcon}`} />
                            <img src={`/assets/svgs/${imageIcon.cornerImg.pngImgIcon}`} alt={imageIcon?.cornerImg?.alt} width="100%" height="100%"
                             className="right-corner-image" />
                        </picture>}
                    </div></div>}
            </div>

        </div>
    );
});

const mapDispatchToProps = (dispatch) => ({
    dispatchSendActivityTracking: (trackingObj) => dispatch(sendActivityTracking(trackingObj))
});

const mapStateToProps = (state) => ({
    userInfo: state.userProfile.userInfo,
    whiteLabelConfig: state.common.whiteLabelConfig
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TryPomandaPlus));