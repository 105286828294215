import React, { memo } from 'react'
import './WhatPomanda.css'
import MediaQuery from 'react-responsive';
const WhatPomanda = (props) => {
    const {
        imageUrl = "",
        webPimageUrl = "",
        pomandaDetails = {}
    } = props

    const {
        mainHeading = "",
        description = "",
        btnComp = {},
        pomandaStats = []
    } = pomandaDetails

    const {
        btnLabel = "",
        btnCss = "",
        onBtnClick = () => { },
        ButtonComp = ""
    } = btnComp

    const getAltAttribute = (imagePath) => {
        if (!imagePath) {
            return "";
        }
        const imageName = imagePath.split("/");
        const altTagAttribute = imageName[imageName.length - 1].split(".")[0];
        return `${altTagAttribute}_image`;
    }
    return (
        <div className='what-pomanda-wrapper-new'>
                <div className='what-pomanda-container-new'>
                    <div className='what-pomanda-text-section-new'>
                        <div className='what-pomanda-desc'>
                            <div className='pb-4'>
                                <p className='what-pomanda-heading-new'>{mainHeading}</p>
                                <span className='what-pomanda-divider'></span>
                                <p className='what-pomanda-details'>{description}</p>
                                <ButtonComp
                                    text={btnLabel}
                                    onBtnClick={onBtnClick}
                                    cmpCssClassStr={btnCss}
                                />
                            </div>
                        </div>
                        <div className='what-pomanda-img-stats-container-tab'>
                            <div className='what-pomanda-stats'>
                                {pomandaStats && pomandaStats.map(statObj => {
                                    return <div className='what-pomanda-data-points' key={statObj.heading}>
                                        <span className='what-pomanda-data-label'>{statObj.heading}</span>
                                        <span className='what-pomanda-label-text'>{statObj.subHeading}</span>
                                    </div>
                                })}
                            </div>
                            <MediaQuery minWidth={576} maxWidth={991.98}>
                                <div className='what-pomanda-img-section-new'>
                                    <picture>
                                        <source type="image/webp" data-srcset={webPimageUrl} />
                                        <img data-src={imageUrl} className="lazyload" width="524" height="341"
                                            alt={getAltAttribute(imageUrl)}
                                        />
                                    </picture>
                                </div>
                            </MediaQuery>
                        </div>
                    </div>
                    <MediaQuery minWidth={992}>
                        <div className='what-pomanda-img-section-new'>
                            <picture>
                                <source type="image/webp" data-srcset={webPimageUrl} />
                                <img data-src={imageUrl} className="lazyload" width="542" height="575"
                                    alt={getAltAttribute(imageUrl)}
                                />
                            </picture>
                        </div>
                    </MediaQuery>
                </div>
        </div>
    )
}

export default memo(WhatPomanda)