import React from "react";
import "./TestimonyCard.css"

const TestimonyCard = (props) => {
    const {
        description = "",
        name = "",
        role = "",
        cmpCssClassStr = ""
    } = props;

    return <div className="testimony-card-wrapper">
        <div className={`testimony-card-container d-flex flex-column justify-content-between ${cmpCssClassStr}`}>
            <div className="testimony-header">
                <img src="/assets/svgs/yellow_quote.svg" className="" width="24" height="20" alt="yellow_quote" />
                <p className="testimony-description mb-0 mt-1 mt-md-3">{description}</p>
            </div>
            <div className="testimony-footer">
                <p className="testimony-author pom-font-18-18-20 font-weight-bold mb-0">{name} <br /> <span className="testimonial-role pom-fw-400 pom-font-16 grey-color-text">{role}</span></p>
            </div>
        </div>
    </div>
}

export default TestimonyCard;