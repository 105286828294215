module.exports = {
    CarouselDirectorConfig: {
        desktop_xl: {
            breakpoint: { min: 1201, max: 4000 },
            items: 5,
            itemsToFetch: 6,
            partialVisibilityGutter: 0,
            slidesToSlide: 2
        },
        desktop_lg: {
            breakpoint: { min: 992, max: 1200.98 },
            items: 4,
            itemsToFetch: 5,
            partialVisibilityGutter: 0,
            slidesToSlide: 2
        },
        tablet: {
            breakpoint: { min: 576, max: 991.98 },
            items: 3,
            partialVisibilityGutter: 50,
            itemsToFetch: 4,
            slidesToSlide: 2
        },
        mobile: {
            breakpoint: { min: 0, max: 575.98 },
            items: 1,
            partialVisibilityGutter: 50,
            itemsToFetch: 2,
            slidesToSlide: 1
        }
    },

    CarouselComapnyConfig: {
        desktop_xl: {
            breakpoint: { min: 1201, max: 4000 },
            items: 1,
            itemsToFetch: 1,
            partialVisibilityGutter: 0,
            slidesToSlide: 1
        },
        desktop_lg: {
            breakpoint: { min: 992, max: 1200.98 },
            items: 1,
            itemsToFetch: 1,
            partialVisibilityGutter: 0,
            slidesToSlide: 1
        },
        tablet: {
            breakpoint: { min: 576, max: 991.98 },
            items: 1,
            partialVisibilityGutter: 0,
            itemsToFetch: 1,
            slidesToSlide: 1
        },
        mobile: {
            breakpoint: { min: 0, max: 575.98 },
            items: 1,
            partialVisibilityGutter: 0,
            itemsToFetch: 2,
            slidesToSlide: 1
        }
    },
    CarouselValuationReportPPCConfig: {
        desktop_xl: {
            breakpoint: { min: 1201, max: 4000 },
            items: 4,
            itemsToFetch: 5,
            partialVisibilityGutter: 0,
            slidesToSlide: 2
        },
        desktop_lg: {
            breakpoint: { min: 992, max: 1200.98 },
            items: 4,
            itemsToFetch: 5,
            partialVisibilityGutter: 0,
            slidesToSlide: 2
        },
        tablet: {
            breakpoint: { min: 576, max: 991.98 },
            items: 2,
            partialVisibilityGutter: 50,
            itemsToFetch: 3,
            slidesToSlide: 2
        },
        mobile: {
            breakpoint: { min: 0, max: 575.98 },
            items: 1,
            partialVisibilityGutter: 50,
            itemsToFetch: 2,
            slidesToSlide: 1
        }
    },
    CarouselBusinessHomePPCConfig: {
        desktop_xl: {
            breakpoint: { min: 1201, max: 4000 },
            items: 2,
            itemsToFetch: 4,
            partialVisibilityGutter: 0,
            slidesToSlide: 2
        },
        desktop_lg: {
            breakpoint: { min: 992, max: 1200.98 },
            items: 2,
            itemsToFetch: 4,
            partialVisibilityGutter: 0,
            slidesToSlide: 2
        },
        tablet: {
            breakpoint: { min: 576, max: 991.98 },
            items: 2,
            partialVisibilityGutter: 50,
            itemsToFetch: 4,
            slidesToSlide: 2
        },
        mobile: {
            breakpoint: { min: 0, max: 575.98 },
            items: 1,
            partialVisibilityGutter: 50,
            itemsToFetch: 2,
            slidesToSlide: 1
        }
    },
    CarouselHomepageSolutionsCard: {
        mobile: {
            breakpoint: { min: 0, max: 575.98 },
            items: 1,
            partialVisibilityGutter: 50,
            slidesToSlide: 1
        }
    },
    CarouselTestimonialsConfig: {
        tablet: {
            breakpoint: { min: 576, max: 991.98 },
            items: 1,
            partialVisibilityGutter: 0,
            slidesToSlide: 1
        },
        mobile: {
            breakpoint: { min: 0, max: 575.98 },
            items: 1,
            partialVisibilityGutter: 50,
            slidesToSlide: 1
        }
    }
}


