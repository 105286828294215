import React from "react";
import "./TestimonialCarouselComponent.css"
import MediaQuery from "react-responsive";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import TestimonyCard from "../TestimonyCard/TestimonyCard";
import { CarouselTestimonialsConfig } from "../../../common/CarouselConfig";

const TestimonialCarouselComponent = (props) => {
    const {
        testimonials = []
    } = props;

    return <div className="testimonial-carousel-component">
        <Carousel
            responsive={CarouselTestimonialsConfig}
            showDots
            partialVisbile
            slidesToSlide={1}
            swipeable
            draggable
            autoPlay
            autoPlaySpeed={4000}
            infinite
            pauseOnHover
            deviceType="tablet"
            ssr
            arrows={false}
            sliderClass="testimonial-slider-track"
            containerClass="testimonial-carousel-container"
        >
            {testimonials.map((testimonyObj, i) => {
                return <TestimonyCard
                    key={`testimony_card_${i}`}
                    {...testimonyObj}
                />
            })}
        </Carousel>
        <MediaQuery minWidth={567} maxWidth={991.98}>
            <picture>
                <source data-srcset="/assets/webpImages/testimonial_tablet_img.webp" />
                <img data-src="/assets/images/testimonial_tablet_img.png" className="lazyload testimonial-tablet-img" width="712" height="280" alt="testimonial_tablet_img" />
            </picture>
        </MediaQuery>
    </div>
}

export default TestimonialCarouselComponent;