import React from 'react';

const YellowBgButton = (props) => {
    const {
        text = "",
        onBtnClick = () => { },
        cmpCssClassStr = "",
        disabled = false,
        preIconObj = {},
        postIconObj = {},
        btnType = ""
    } = props;
    return (
        <button type={btnType} disabled={disabled} className={`yellow-E1A132-btn white-color-text business-temp-font-12-14-14 business-temp-yellow-bg-button ${disabled ? "buss-plan-opacity-50" : ""} ${cmpCssClassStr}`} onClick={onBtnClick} pomandae2e={text}>
            {preIconObj?.type === "icon" && <i className={preIconObj.classStr} aria-hidden="true" />}
            <span className='mx-3'>{text}</span>
            {postIconObj?.type === "icon" ?
                <i className={postIconObj.classStr} aria-hidden="true" /> :
                postIconObj?.type === "img" ?
                    <img src={postIconObj.url} alt={postIconObj.alt} className={postIconObj.cssClassStr} width={postIconObj.width} height={postIconObj.height} /> :
                    null
            }
        </button>
    )
}

export default YellowBgButton;