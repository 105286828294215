import React, { memo } from 'react'
import './OurFeature.css'
import RightSideImageLeftSideText from '../RightSideImageLeftSideText/RightSideImageLeftSideText'
import LeftSideImageRightSideText from '../LeftSideImageRightSideText/LeftSideImageRightSideText'
const OurFeatures = () => {
    return (
        <div className="pomanda-home-page-feature-section">
            <div className='pomanda-home-page-feature-section-container lead_generation_container'>
                <RightSideImageLeftSideText
                    componentName="lead_generation"
                    imageUrl="/assets/images/lead_generation_feature.png"
                    webPimageUrl="/assets/webpImages/lead_generation_feature.webp"
                    logoPath="/assets/svgs/lead_generation_feature_icon.svg"
                    tabletViewClass="lead-gen-img-tablet-view"
                    isShowMoreFunc={{
                        isMobile: true
                    }}
                    featureDetailsArr={[
                        {
                            header: "Lead Generation",
                            paragraphs: [
                                "Pomanda's Power Search allows you to filter the entire UK market by hundreds of parameters to identify and source new business leads. These leads can then be exported to use with your CRM system.",
                                "Unlike Companies House search, Pomanda’s Power Search is a lead generation tool that enables users to instantly explore UK businesses, company directors, industries, shareholders and charges.",
                                "Covering the entire UK market and specialising in SME data, Pomanda is the ultimate sales tool to identify new customers, leads and opportunities of any size."
                            ],
                        }
                    ]}
                />
            </div>
            <div className='pomanda-home-page-feature-section-container'>
                <LeftSideImageRightSideText
                    componentName="research_analytics"
                    imageUrl="/assets/images/when_we_calculate_estimates.png"
                    webPimageUrl="/assets/webpImages/when_we_calculate_estimates.webp"
                    logoPath="/assets/svgs/research_analytics_feature_icon.svg"
                    featureDetailsArr={[{
                        header: "Research & Analytics",
                        paragraphs: ["Get a detailed view of the UK market and analyse market trends on our UK industry and UK region insight pages, updated weekly.", "Conduct due diligence on individuals & UK businesses by getting big data on companies of every size. Pomanda's estimates fills in the gaps left by Companies House for every UK company no matter what size.", "Pomanda provides company information on over 14 million UK businesses and profiles for over 14 million UK company directors. Pomanda's Company Information and Health Check Report is the perfect introduction to a company providing insights like trading history and competitor analysis. Download company and director reports to gain a full view of a business instantly."
                        ],
                    }]}
                    isShowMoreFunc={{
                        isMobile: true
                    }}
                />
            </div>
            <div className='pomanda-home-page-feature-section-container sme_business_insights_feature_wrapper'>
                <RightSideImageLeftSideText
                    componentName="SME_business_insights"
                    imageUrl="/assets/images/sme_business_insights_feature.png"
                    webPimageUrl="/assets/webpImages/sme_business_insights_feature.webp"
                    logoPath="/assets/svgs/sme_business_insights_feature_icon.svg"
                    tabletViewClass="lead-gen-img-tablet-view"
                    featureDetailsArr={[
                        {
                            header: "SME Business Insights",
                            paragraphs: [
                                "Discover how your small business stacks up against industry peers and competitors. Pinpoint areas for improvement using Pomanda's Company Health Check, our business benchmarking tool. Leverage the Competitor Analysis tool to benchmark against your closest rivals.",
                                "Protect against business risk and analyse your supply chain with unlimited Company and Director profiles. With company information ranging from SME financials to shareholders, understanding who you conduct business with has never been easier.",
                                "Interested in buying or selling a business? Get an instant bespoke Company Valuation with Pomanda's Business Valuation Calculator based on industry EBITDA multiples, Turnover Multiples or Price to Book valuations."
                            ],
                        }
                    ]}
                    isShowMoreFunc={{
                        isMobile: true
                    }}
                />
            </div>
        </div>
    )
}
export default memo(OurFeatures)